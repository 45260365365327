.x1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.x1s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.x10 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.x10s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.x11 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.x11s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.x13 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.x13s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.x17 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.x17s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.x19 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.x19s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.y1 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.y1s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.y10 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.y10s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.y11 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.y11s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.y13 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.y13s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.y17 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.y17s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.y19 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.y19s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.x2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.x2s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.x2a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.x2as {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.x2b {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.x2bs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.x2e {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.x2es {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.x20 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.x20s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.x20a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.x20as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.x20b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.x20bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.x20e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.x20es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.x21 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.x21s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.x21a {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.x21as {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.x21b {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.x21bs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.x21e {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.x21es {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.x23 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.x23s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.x23a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.x23as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.x23b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.x23bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.x23e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.x23es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.x27 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.x27s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.x27a {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.x27as {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.x27b {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.x27bs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.x27e {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.x27es {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.x29 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.x29s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.x29a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.x29as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.x29b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.x29bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.x29e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.x29es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.y2 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.y2s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.y20 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.y20s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.y21 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.y21s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.y23 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.y23s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.y27 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.y27s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.y29 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.y29s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.x3 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.x3s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.x30 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.x30s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.x31 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.x31s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.x33 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.x33s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.x37 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.x37s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.x39 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.x39s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.y3 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.y3s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.y30 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.y30s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.y31 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.y31s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.y33 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.y33s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.y37 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.y37s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.y39 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.y39s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.x4 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.x4s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.x40 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.x40s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.x41 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.x41s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.x43 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.x43s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.x47 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.x47s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.x49 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.x49s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.y4 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.y4s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.y4a {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.y4as {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.y4b {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.y4bs {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.y4e {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.y4es {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.y40 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.y40s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.y40a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.y40as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.y40b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.y40bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.y40e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.y40es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.y41 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.y41s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.y41a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.y41as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.y41b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.y41bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.y41e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.y41es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.y43 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.y43s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.y43a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.y43as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.y43b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.y43bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.y43e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.y43es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.y47 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.y47s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.y47a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.y47as {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.y47b {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.y47bs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.y47e {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.y47es {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.y49 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.y49s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.y49a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.y49as {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.y49b {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.y49bs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.y49e {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.y49es {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.x5 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.x5s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.x5a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.x5as {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.x5b {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.x5bs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.x5e {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.x5es {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.x50 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.x50s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.x50a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.x50as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.x50b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.x50bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.x50e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.x50es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.x51 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.x51s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.x51a {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.x51as {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.x51b {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.x51bs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.x51e {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.x51es {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.x53 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.x53s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.x53a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.x53as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.x53b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.x53bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.x53e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.x53es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.x57 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.x57s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.x57a {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.x57as {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.x57b {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.x57bs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.x57e {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.x57es {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.x59 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.x59s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.x59a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.x59as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.x59b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.x59bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.x59e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.x59es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.y5 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.y5s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.y5a {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.y5as {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.y5b {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.y5bs {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.y5e {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.y5es {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.y50 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.y50s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.y50a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.y50as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.y50b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.y50bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.y50e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.y50es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.y51 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.y51s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.y51a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.y51as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.y51b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.y51bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.y51e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.y51es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.y53 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.y53s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.y53a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.y53as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.y53b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.y53bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.y53e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.y53es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.y57 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.y57s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.y57a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.y57as {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.y57b {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.y57bs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.y57e {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.y57es {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.y59 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.y59s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
.y59a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.y59as {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}
.y59b {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.y59bs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}
.y59e {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.y59es {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: center;
}
.x6 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.x6s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.x60 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.x60s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.x61 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.x61s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.x63 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.x63s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.x67 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.x67s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.x69 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.x69s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.y6 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.y6s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.y6a {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.y6as {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.y6b {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.y6bs {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.y6e {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.y6es {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.y60 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.y60s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.y60a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.y60as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.y60b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.y60bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.y60e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.y60es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.y61 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.y61s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.y61a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.y61as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.y61b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.y61bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.y61e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.y61es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.y63 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.y63s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.y63a {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.y63as {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.y63b {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.y63bs {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.y63e {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.y63es {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.y67 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.y67s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.y67a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.y67as {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.y67b {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.y67bs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.y67e {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.y67es {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.y69 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.y69s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.y69a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.y69as {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.y69b {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.y69bs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.y69e {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.y69es {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.x7 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.x7s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.x70 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.x70s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.x71 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.x71s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.x73 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.x73s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.x77 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.x77s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.x79 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.x79s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.y7 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.y7s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.y70 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.y70s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.y71 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.y71s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.y73 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.y73s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.y77 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.y77s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.y79 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.y79s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.x8 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.x8s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.x8a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.x8as {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.x8b {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.x8bs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.x8e {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.x8es {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.x80 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.x80s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.x80a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.x80as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.x80b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.x80bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.x80e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.x80es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.x81 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.x81s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.x81a {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.x81as {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.x81b {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.x81bs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.x81e {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.x81es {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.x83 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.x83s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
}
.x83a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: flex-end;
  align-content: flex-end;
}
.x83as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-end;
}
.x83b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
.x83bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}
.x83e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
}
.x83es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-end;
}
.x87 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.x87s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.x87a {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.x87as {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.x87b {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.x87bs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.x87e {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.x87es {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.x89 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.x89s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}
.x89a {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}
.x89as {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;
}
.x89b {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.x89bs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.x89e {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
}
.x89es {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
}
.y8 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.y8s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.y80 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.y80s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.y81 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.y81s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.y83 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.y83s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
}
.y87 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.y87s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.y89 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.y89s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.x9 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.x9s {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.x90 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.x90s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.x91 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.x91s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.x93 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.x93s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.x97 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.x97s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.x99 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.x99s {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.y9 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.y9s {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.y90 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.y90s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.y91 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
.y91s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-end;
}
.y93 {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}
.y93s {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: stretch;
  align-content: flex-start;
}
.y97 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}
.y97s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-end;
}
.y99 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.y99s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
